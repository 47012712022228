import { styled } from '@mui/system';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Grid, IconButton, Typography, useTheme, useMediaQuery } from '@mui/material';
import { Carousel } from 'react-responsive-carousel';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { CSSProperties } from 'react';
import { cyan } from '@mui/material/colors';
import quote from './imgs/quote.png';

export function CarouselBlock() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  return (
    <Article>
      <Carousel
        showThumbs={false}
        showStatus={false}
        autoPlay={true}
        infiniteLoop={true}
        interval={5000}
        renderArrowPrev={(onClickHandler, hasPrev, label) =>
          hasPrev && (
            <IconButton onClick={onClickHandler} style={{ ...arrowStyles, left: matches ? -10 : 15, color: cyan[700] }}>
              <KeyboardArrowLeftIcon fontSize="large" />
            </IconButton>
          )
        }
        renderArrowNext={(onClickHandler, hasNext, label) =>
          hasNext && (
            <IconButton
              onClick={onClickHandler}
              style={{ ...arrowStyles, right: matches ? -10 : 15, color: cyan[700] }}>
              <KeyboardArrowRightIcon fontSize="large" />
            </IconButton>
          )
        }
        renderIndicator={(onClickHandler, isSelected, index, label) => {
          if (isSelected) {
            return (
              <li
                style={{ ...indicatorStyles, background: cyan[700] }}
                aria-label={`Selected: ${label} ${index + 1}`}
                title={`Selected: ${label} ${index + 1}`}
              />
            );
          }
          return (
            <li
              style={indicatorStyles}
              onClick={onClickHandler}
              onKeyDown={onClickHandler}
              value={index}
              key={index}
              role="button"
              tabIndex={0}
              title={`${label} ${index + 1}`}
              aria-label={`${label} ${index + 1}`}
            />
          );
        }}>
        {carouselList.map((carousel, idx) => {
          return (
            <Grid
              key={idx}
              container
              spacing={2}
              justifyContent="center"
              sx={{
                marginBottom: theme.spacing(4),
              }}>
              <Grid item xs={12}>
                <QuoteImage src={quote} alt="Quote" />
              </Grid>

              <Grid item xs={10} sm={8} style={{ marginBottom: '18px' }}>
                <Typography variant="h5" color={cyan[700]}>
                  {carousel.title}
                </Typography>
              </Grid>

              <Grid item xs={10} sm={8}>
                <Typography variant="body1" paragraph>
                  <i>{carousel.content}</i>
                </Typography>
              </Grid>
            </Grid>
          );
        })}
      </Carousel>
    </Article>
  );
}

const arrowStyles: CSSProperties = {
  position: 'absolute',
  zIndex: 2,
  top: 'calc(50% - 15px)',
  width: 45,
  height: 45,
  cursor: 'pointer',
};

const indicatorStyles: CSSProperties = {
  background: '#C4C4C4',
  width: 10,
  height: 10,
  borderRadius: '12px',
  display: 'inline-block',
  margin: '0 8px',
  cursor: 'pointer',
};

const Article = styled('article')(({ theme }) => ({
  textAlign: 'center',
  padding: theme.spacing(4, 8, 4, 8),
  backgroundColor: '#f2ebdf',
  [theme.breakpoints.down('xs')]: {
    padding: theme.spacing(4, 2, 0, 2),
  },
}));

const QuoteImage = styled('img')(({ theme }) => ({
  width: '80px !important',
  height: '80px',
  [theme.breakpoints.down('sm')]: {
    width: '55px !important',
    height: '55px',
  },
}));

const carouselList = [
  {
    title: 'Mum of a 12-year-old boy',
    content:
      'The course actually reminded me to trust the groundwork I had already laid with my kids and trust them to find a way through - which my son did! It was only a month or so before the boy I recognised returned and we were able to get our communication strategies back on track.',
  },
  {
    title: 'Dad with a Vietnamese background',
    content:
      'This is a good program especially for parents from a migrant background. This program gave me insight about parenting in Australian context.',
  },
  {
    title: 'Step-mum of a 14-year-old girl',
    content:
      'The program kept me reflecting on my parenting as different situations and challenges arise during this very unique year - 2020.  I love the emails that remind me to get back online and do another unit every fortnight or so.',
  },
  {
    title: 'Dad of a 16-year-old boy',
    content:
      'I feel that it did reinforce ideas I already had about parenting and encouraged me to be really open with my son so he felt comfortable discussing his anxiety with me. Open communication is so important and the program encouraged this.',
  },
];
