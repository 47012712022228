import { Grid, Typography, useTheme } from '@mui/material';
import { cyan } from '@mui/material/colors';
import { styled } from '@mui/system';

export function Footer() {
  const theme = useTheme();

  return (
    <Article>
      <Grid container spacing={2}>
        <Grid item xs={12} container justifyContent="center">
          <Grid
            item
            xs={12}
            md={8}
            sx={{
              marginBottom: theme.spacing(3),
            }}
          >
            <Typography variant="h5" color={cyan[700]}>
              Contribute to the important research on parenting & teen mental health by Monash University and Deakin
              University.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Article>
  );
}

const Article = styled('article')(({ theme }) => ({
  textAlign: 'center',
  padding: theme.spacing(4, 8, 8, 8),
  backgroundColor: '#e9f7fa',
  [theme.breakpoints.down('xs')]: {
    padding: theme.spacing(4, 2, 4, 2),
  },
}));
