import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { cyan } from '@mui/material/colors';

export default function ModuleAccordian() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Grid
      container
      spacing={matches ? 0 : 2}
      sx={{
        textAlign: 'left',
        padding: theme.spacing(0, 12),
        [theme.breakpoints.down('md')]: {
          padding: theme.spacing(0, 4),
        },
        [theme.breakpoints.down('sm')]: {
          padding: theme.spacing(0, 2),
        },
      }}>
      {modules.map((topic, idx) => {
        return (
          <Grid key={idx} item xs={12} sm={6} container>
            <Grid
              item
              xs={1}
              sx={{
                marginTop: theme.spacing(3.2),
                borderColor: cyan[700],
                borderTopStyle: 'solid',
              }}
            />

            <Grid item xs={11}>
              <Accordion
                elevation={0}
                sx={{
                  backgroundColor: 'transparent',
                }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{
                    flexDirection: 'row',
                    minHeight: theme.spacing(6.4),
                    height: theme.spacing(6.4),
                    padding: '0 16px',
                    margin: 0,
                    '&.Mui-expanded': {
                      margin: 0,
                      minHeight: theme.spacing(6.4),
                      height: theme.spacing(6.4),
                    },
                  }}>
                  <Typography
                    sx={{
                      fontSize: theme.typography.pxToRem(17),
                      [theme.breakpoints.down('xs')]: {
                        fontSize: theme.typography.pxToRem(15),
                      },
                    }}>
                    {topic.title}
                  </Typography>
                </AccordionSummary>

                <AccordionDetails>
                  <Typography
                    sx={{
                      fontSize: theme.typography.pxToRem(15),
                      [theme.breakpoints.down('xs')]: {
                        fontSize: theme.typography.pxToRem(13),
                      },
                    }}>
                    {topic.description}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
}

const modules = [
  {
    title: 'Understanding anxiety and depression',
    description:
      'It can be hard for parents to tell whether changes in their teen’s behaviours are a normal part of adolescence, or a sign of a mental health problem. In this module, we’ll take a closer look at depression and anxiety disorders in teens, including how you can support your teen’s mental health now and into the future.',
  },
  {
    title: 'Connect',
    description:
      'Connect is about empowering you to change your behaviour so you can positively influence your teenager. We’ll talk about ways to build your relationship and communication with your teenager.',
  },
  {
    title: 'Breaking the anxiety cycle',
    description:
      'We all feel anxious at times, and it’s important for teenagers to learn to manage their everyday anxiety. ‘From surviving to thriving: Helping your teenager deal with anxiety’ will talk about ways that you can support your teenager to cope with the fears and worries they will inevitably face.',
  },
  {
    title: 'Understanding school refusal',
    description:
      'When teens refuse to attend school, the problem can be more complex than it initially appears. This module will help you develop a solid understanding of the problem, to equip you with the knowledge and skills needed to support your teen to engage with school.',
  },
  {
    title: 'Working together to overcome school refusal',
    description:
      'To give your teen the best chance of overcoming school refusal, it’s essential to work together with your teen, the school, and any other professionals involved. This module is about how you can work together effectively to develop a tailored plan to support your teen.',
  },
  {
    title: 'Raising good kids into great adults',
    description:
      'Teenagers need the adults around them to set clear boundaries and expectations for their behaviour. This module will help you to establish clear expectations that your teen is likely to follow.',
  },
  {
    title: 'Calm versus conflict',
    description:
      'Parents play an important role in helping their teenagers learn to manage conflict. ‘Calm versus conflict’ aims to help you manage conflict at home, to create a safe and supportive environment for your teenager.',
  },
  {
    title: 'Good friends = supportive relationships',
    description:
      'As teenagers begin to place greater emphasis on their peers, parents still play an important role in helping their teens to develop social skills. This module helps you to support your teen to develop social skills across a variety of settings.',
  },
  {
    title: 'Nurture roots & inspire wings',
    description:
      '‘Nurture roots’ is all about finding a balanced way to stay involved in your teenager’s life while also allowing them to ‘leave the nest’ and develop age-appropriate independence.',
  },
  {
    title: 'Good health habits for good mental health',
    description:
      'Getting enough sleep, eating well, being physically active, and not using alcohol or drugs can protect our physical and mental health. This module is all about helping you to support your teenager to develop good health habits.',
  },
  {
    title: 'Partners in problem solving',
    description:
      'Problems are a part of life. As teenagers mature, they need to learn strategies to tackle the problems they will face throughout their life. ‘Partners in problem solving’ focusses on how you as a parent can support your teenager to develop good problem-solving skills.',
  },
  {
    title: 'Parenting through the pandemic',
    description:
      'The COVID-19 pandemic has brought with it many new challenges for parents and families. This module aims to support you and your teen to navigate the ‘new normal’ and respond to the challenges of the pandemic with confidence.',
  },
  {
    title: 'Maintaining the gains',
    description:
      'It is normal for a teenager’s mental health to fluctuate. Maintaining the Gains is about how you can support your teen to continue looking after their mental health, even when challenges or setbacks arise.',
  },
];
