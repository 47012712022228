import { Box, Button, Container, Grid, Hidden, Paper, Typography, useTheme } from '@mui/material';
import { cyan } from '@mui/material/colors';
import { Light, Studio, Earphone } from './assets';

export function RoofBlock() {
  const theme = useTheme();

  const cardStyle = {
    backgroundColor: cyan[100],
    padding: theme.spacing(3, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      height: '100%',
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(3, 1),
    },
  };

  return (
    <article>
      <Box
        sx={{
          borderTopStyle: 'solid',
          borderTopWidth: 6,
          borderTopColor: '#333',
          height: 125,
          background: `linear-gradient(180deg, ${cyan[400]} 0%, ${cyan[50]} 100%)`,
          overflow: 'hidden',
        }}>
        <Container>
          <Grid container spacing={2}>
            <Grid item sm={12} md={6} sx={lightItemStyle}>
              <Light sx={lightStyle} />
            </Grid>
            <Hidden smDown>
              <Grid item sm={12} md={6} sx={lightItemStyle}>
                <Light sx={lightStyle} />
              </Grid>
            </Hidden>
          </Grid>
        </Container>
      </Box>

      <Box bgcolor={cyan[50]} p={3}>
        <Container>
          <Grid container spacing={2}>
            <Grid item sm={12} md={6}>
              <Paper sx={cardStyle} elevation={0}>
                <Studio />
                <Typography color={cyan[700]} variant="h6" align="center" gutterBottom>
                  Evidence-based parenting program
                </Typography>

                <Box flex={1} mt={2}>
                  <Typography color={cyan[700]} variant="body1" align="center">
                    PiP-Ed content is based on:
                  </Typography>

                  <Typography component="span" variant="body1" align="center">
                    <ul style={{ padding: 0, margin: 0 }}>
                      <li>High-quality research evidence and international expert consensus</li>
                      <li>
                        Parenting guidelines:{' '}
                        <i>‘Responding to school reluctance or refusal: Strategies for parents’</i> and{' '}
                        <i>‘Reducing teenagers’ risk of depression and anxiety disorders: Strategies for parents’</i>
                      </li>
                    </ul>
                  </Typography>
                </Box>

                <Box mt={2} justifySelf="flex-end">
                  <Button sx={{ color: cyan[700] }} href="/resources" target="__blank">
                    More Resources
                  </Button>
                </Box>
              </Paper>
            </Grid>

            <Grid item sm={12} md={6}>
              <Paper sx={cardStyle} elevation={0}>
                <Earphone />
                <Typography color={cyan[700]} variant="h6" align="center" gutterBottom>
                  1,500+ families helped
                </Typography>

                <Box flex={1} mt={2}>
                  <Typography variant="body1" paragraph align="center">
                    Our research trials with over 1,500 Australian parents show that PiP is effective, with benefits for
                    both parents and teens.
                  </Typography>

                  <Typography variant="body1" paragraph align="center">
                    If you’d like more information about the evidence for our programs, feel free to contact us.
                  </Typography>
                </Box>

                <Box mt={2} justifySelf="flex-end">
                  <Button sx={{ color: cyan[700] }} href="/contact" target="__blank">
                    Contact us
                  </Button>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </article>
  );
}

const lightItemStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '100%',
};

const lightStyle = {
  width: 359,
  height: 246,
};
