import { styled } from '@mui/system';
import { Grid, Box, Typography, useTheme } from '@mui/material';
import { cyan } from '@mui/material/colors';
import responsive from './imgs/responsive.png';
import interactive from './imgs/interactive.png';

export function MoreDetailBlock() {
  const theme = useTheme();

  return (
    <Article>
      <Grid container spacing={2}>
        {/* Fits Lifestyle Section */}
        <Grid item xs={12} md={6} style={{ order: 1 }}>
          <Box display="flex" justifyContent="center">
            <Image src={responsive} alt="Responsive" />
          </Box>
        </Grid>

        <Grid item xs={12} md={6} container alignContent="center" style={{ order: 2 }}>
          <Grid item xs={12} style={{ marginBottom: '18px' }}>
            <Typography variant="h5" color={cyan[700]}>
              Fits your lifestyle
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography
              component="span"
              variant="body1"
              sx={{
                padding: 0,
                listStylePosition: 'inside',
              }}>
              <ul style={{ padding: 0, margin: 0 }}>
                <li>
                  Only <b>20-35</b> minutes per module
                </li>
                <li>Complete the program at your own pace</li>
                <li>Entirely online</li>
                <li>Both desktop and mobile versions available</li>
              </ul>
            </Typography>
          </Grid>
        </Grid>

        {/* Fun and Interactive Section */}
        <Grid
          item
          xs={12}
          md={6}
          container
          alignContent="center"
          sx={{
            order: 3,
            margin: theme.spacing(4, 0, 4, 0),
            [theme.breakpoints.down('sm')]: {
              order: 4,
              margin: theme.spacing(4, 0, 0, 0),
            },
          }}>
          <Grid item xs={12} style={{ marginBottom: '18px' }}>
            <Typography variant="h5" color={cyan[700]}>
              Fun and interactive
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography
              component="span"
              variant="body1"
              sx={{
                padding: 0,
                listStylePosition: 'inside',
              }}>
              <ul style={{ padding: 0, margin: 0 }}>
                <li>Learn practical strategies through interactive activities and video-based scenarios</li>
                <li>
                  Hear from teenagers, parents, and educational professionals with lived experience of school refusal
                </li>
                <li>Apply your module learnings through weekly goals</li>
              </ul>
            </Typography>
          </Grid>

          <Grid item xs={12} style={{ marginTop: '36px' }}>
            <Typography variant="h5" color={cyan[700]}>
              Free to join
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography
              component="span"
              variant="body1"
              sx={{
                padding: 0,
                listStylePosition: 'inside',
              }}>
              Joining PiP-Ed won’t cost you a cent! You will be invited to complete an optional survey at the end of
              the program.
            </Typography>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          sx={{
            order: 4,
            alignSelf: 'flex-end',
            [theme.breakpoints.down('sm')]: {
              order: 3,
            },
          }}>
          <Box display="flex" justifyContent="center">
            <Image src={interactive} alt="Interactive" />
          </Box>
        </Grid>
      </Grid>
    </Article>
  );
}

const Image = styled('img')({
  width: '70%',
  height: '70%',
  objectFit: 'cover',
});

const Article = styled('article')(({ theme }) => ({
  padding: theme.spacing(4, 8, 4, 8),
  backgroundColor: '#f3fcfd',
  [theme.breakpoints.down('xs')]: {
    textAlign: 'center',
    padding: theme.spacing(4, 2, 4, 2),
  },
}));
