import { Container, Grid, Typography, Box, Button, Paper, useTheme } from '@mui/material';
import { buttonColour } from 'app/topics/pip/utils';
import { navigate } from 'gatsby';
import { cyan } from '@mui/material/colors';
import { BasicVideo } from '../topics/pip';

export function Hero() {
  const theme = useTheme();

  return (
    <Container maxWidth="xl" sx={{ marginTop: theme.spacing(8) }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography
            gutterBottom
            variant="h3"
            sx={{
              color: theme.palette.grey[800],
              fontWeight: 700,
              fontSize: theme.typography.h4.fontSize,

              fontFamily: 'Source Sans Pro',
              lineHeight: 2,
            }}>
            Partners in Parenting: Education (PiP-Ed)
          </Typography>
          <Typography variant="body1">
            PiP-Ed is a new, updated version of the award winning, evidence-based <b>parenting program</b> ‘Partners in
            Parenting’. The program is designed to support parents of teenagers struggling with <b>school attendance</b>{' '}
            (often called <b>‘school refusal’</b>) in the context of <b>anxiety</b> <b>and/or depression</b>, by
            equipping parents with strategies to improve their parenting confidence and support their teen’s mental
            health and education. The program is self-guided and completed entirely online.
          </Typography>
          <Box my={4}>
            <Grid container spacing={2}>
              <Grid item>
                <Button
                  variant="contained"
                  sx={{ backgroundColor: buttonColour, '&:hover': { backgroundColor: '#d5d5d5' } }}
                  onClick={() => navigate('/auth/signup')}>
                  Join Now
                </Button>
              </Grid>
              <Grid item>
                <Button
                  sx={{ backgroundColor: buttonColour, '&:hover': { backgroundColor: '#d5d5d5' } }}
                  variant="contained"
                  onClick={() => {
                    navigate('/auth/signin');
                  }}>
                  Sign in
                </Button>
              </Grid>
            </Grid>
          </Box>
          <Paper
            sx={{
              m: theme.spacing(2),
              mr: theme.spacing(4),
              backgroundColor: cyan[100],
              padding: theme.spacing(3, 4),
              display: 'flex',
              flexDirection: 'column',
            }}
            elevation={0}>
            <Typography variant="h6" sx={{ color: cyan[700] }}>
              A note on language:
            </Typography>
            <Typography variant="body1">
              Although widely used, we acknowledge that the term ‘school refusal’ is not universally accepted as the
              best term to describe the behaviour. You may see other terms used, including ‘school phobia’, ‘school
              avoidance’, and ‘school can’t’. For simplicity, we use the term ‘school refusal’ throughout this program.
              We acknowledge that there are numerous and interacting child, family, school, community and other
              circumstances that contribute to a child’s attendance at school.
            </Typography>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ width: '100%' }}>
            <Typography variant="h6" sx={{ color: cyan[700] }}>
              Introductory video{' '}
            </Typography>
            <Typography variant="body1">Watch this short video to learn more about the PiP-Ed program.</Typography>
            <BasicVideo url="https://player.vimeo.com/video/844069376?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
